import { FC } from 'react';
import { Row, Text } from 'lavaa';

type Props = {
    value: string;
};

// Get Color
const getColor = (value: string) => {
    const valueNum = Number(value);

    // Green
    if (valueNum >= 1 && valueNum < 4) {
        return 'var(--success-text-color, #2AA40C)';
    }

    // Orange
    else if (valueNum >= 4 && valueNum <= 6) {
        return 'var(--warning-text-color, #FF9800)';
    }

    // Red
    else if (valueNum >= 7) {
        return 'var(--danger-text-color, #E40931)';
    }
};

// LavaaWorryScoreCellRenderer Component
export const LavaaWorryScoreCellRenderer: FC<Props> = (props: Props) => {
    const { value } = props;
    
    return (
        <Row shrink="1" justifycontent="center">
            <Text size="x3" bold="true" style={{color: getColor(value)}}>
                {value}
            </Text>
        </Row>
    )
};