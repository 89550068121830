import React, {FC, useContext, useEffect} from "react";
import {AppCtx} from "../../Context/App.context";
import {PatientPageDesktop} from "./Desktop/PatientPageDesktop";
import {PatientPageMobile} from "./Mobile/PatientPageMobile";
import {useNavigate, useParams} from "react-router-dom";
import {useDemo} from "../../Hooks/useDemo";
import {useTypes} from "../../Hooks/UseTypes";
import {ModalsCtx} from "../../Context/Modals.context";
import {useClients} from "../../Hooks/UseClients";
import {useModules} from "../../Hooks/UseModules";
import {useQuestionnaire} from "../../Hooks/UseQuestionnaire";
import {useAccount} from "../../Hooks/UseAccount";

// Patient Page
const PatientPage: FC<any> = React.memo(() => {

    let { deviceType } = useContext(AppCtx);
    const navigate = useNavigate();

    const {setPersonEditOutputModalActive} = useContext(ModalsCtx);
    const {getGetClientICD10Codes, getClientScores} = useClients();
    const {fetchDemoClient, activeFlowId, activeClient, updateLocalFlowId, localFlowId} = useDemo(true, false, true);
    const {patientId} = useParams();
    const {fetchModules} = useModules();
    const {fetchTypes} = useTypes();
    const {HMSProjectId} = useAccount();
    const {fetchQuestionnaire} = useQuestionnaire();

    // Redirect to patients page if client not found
    useEffect(() => {
        if(activeClient.hasOwnProperty("client") && activeClient.client === null) {
            navigate('/patients/');
        }
    }, [activeClient]);

    useEffect(() => {
        setPersonEditOutputModalActive(false);
        fetchDemoClient(patientId, localFlowId, HMSProjectId);
        getGetClientICD10Codes(patientId, localFlowId, '', HMSProjectId);
    }, [localFlowId, patientId, HMSProjectId]);

    useEffect(() => {
        fetchQuestionnaire(patientId, HMSProjectId)
        getClientScores(patientId, HMSProjectId);
    }, [HMSProjectId, patientId]);

    useEffect(() => {
        updateLocalFlowId(activeFlowId);
        fetchTypes();
        fetchModules();
    }, []);

    if(deviceType === 'desktop') return <PatientPageDesktop/>;

    return (
        <PatientPageMobile/>
    )
});

export {PatientPage};
