import React, {FC} from "react";
import css from "../Card/Card.module.scss";
import {CardItem} from "../Card/Card";
import {RafScoreStat} from "./Stat/RafScoreStat.com";
import {RafScoreMissing} from "./Compare/RafScoreMissing.com";

interface IProps {
}

// Raf Score Page
const RafScorePage: FC<IProps> = React.memo(() => {
    return (
        <div className={css.Grid}>
            <CardItem>
                <RafScoreMissing
                    title="Missed Codes"
                    withDataKey="emrhcc"
                    toDataKey="billingHCC"
                    emptyText="No Missed Codes"
                />
            </CardItem>
            <CardItem>
                <RafScoreMissing
                    title="Suggested Codes"
                    withDataKey="lavaaHCC"
                    toDataKey="emrhcc"
                    emptyText="No Suggested Codes"
                />
            </CardItem>
            <CardItem hSize="doubled">
                <RafScoreStat/>
            </CardItem>
        </div>
    )
});

export {RafScorePage};
