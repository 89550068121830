import { createSlice } from '@reduxjs/toolkit';
import { Fields } from "@react-awesome-query-builder/ui";

interface IClientsTypeState {
    scheme: Fields,
    clientScores: any,
    activeClientICD10Codes: any
}

const initialState:IClientsTypeState = {
    scheme: {},
    clientScores: {},
    activeClientICD10Codes: []
};

export const clientsSlice = createSlice({
    name: 'Clients',
    initialState,
    reducers: {

        // Get Client Scheme
        getClientSchemeAction: (state, action) => {
            console.log('Get Client Scheme Action --- ');
        },

        // Receive Client Scheme
        receiveClientSchemeAction: (state, action) => {
            state.scheme = action.payload;
        },

        // Get Client Score
        getClientScoresAction: (state, action) => {
            console.log('Get Client Scores Action --- ');
        },

        // Receive Client Scores
        receiveClientScoresAction: (state, action) => {
            // state.scheme = action.payload;
            console.log('Receive Client Scores Action --- ', action.payload);
            state.clientScores = action.payload.data;
        },

        // Get Client ICD10 Codes
        getGetClientICD10CodesAction: (state, action) => {
            console.log('Get Client ICD10 Codes Action --- ');
        },

        // Receive Client ICD10 Codes
        receiveClientICD10CodesAction: (state, action) => {
            state.activeClientICD10Codes = action.payload.data;
        },
    }
});

export const {
    getClientSchemeAction,
    receiveClientSchemeAction,
    getGetClientICD10CodesAction,
    receiveClientICD10CodesAction,
    getClientScoresAction,
    receiveClientScoresAction
} = clientsSlice.actions;
export default clientsSlice.reducer;