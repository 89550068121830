import React, {FC} from "react";
import {Col} from "lavaa";
import css from "../RafScore.module.scss";
import {RafScoreStatGroup} from "./RafScoreStatGroup";

interface IProps {
}

// Raf Score Statistics
const RafScoreStat: FC<IProps> = React.memo(() => {
    return (
        <Col className={css.Stat}>
            <Col className={css.StatList}>
                <RafScoreStatGroup title="Raf Score Potential" scoreKey="lavaaHCC"/>
                <RafScoreStatGroup title="Raf Score Current Diagnoses" scoreKey="emrhcc"/>
                <RafScoreStatGroup title="Raf Score Billed this year" scoreKey="billingHCC"/>
                <RafScoreStatGroup title="Raf Score Billed last year" scoreKey="billingPrevYearHCC"/>
            </Col>
        </Col>
    )
});

export {RafScoreStat};
