import React, {FC} from "react";
import css from "./Card.module.scss";
import {Col, iconsTypes, Input, Row, ScrollContainer, Title} from "lavaa";

interface IProps {
    title?: string,
    hasSearch?: boolean,
    searchPlaceholder?: string,
    size?: 'default' | 'doubled',
    hSize?: 'default' | 'doubled',
    children: any,
}

// Card Item
const CardItem: FC<IProps> = React.memo((props) => {
    const {title, hasSearch = false, searchPlaceholder = "Search", children, size = 'default', hSize = 'default'} = props;

    const [search, setSearch] = React.useState<string>('');

    // Add search to the children props
    const renderChildren = () => {
        if (!hasSearch) {
            return children;
        }

        return React.Children.map(children, (child) => {
            return React.cloneElement(child, {
                search: search,
            });
        });
    };

    return (
        <div className={css.Wrapper} data-size={size} data-hsize={hSize}>
            {
                title && (
                    <Row justifycontent="space-between" className={css.Header}>
                        <Row style={{whiteSpace: 'nowrap'}} grow="1">
                            <Title size='x5'>{title}</Title>
                        </Row>
                        {
                            hasSearch && (
                                <Row className={css.Search}>
                                    <Input
                                        onChange={setSearch}
                                        type="text"
                                        placeholder={searchPlaceholder}
                                        icon={iconsTypes.search}
                                    />
                                </Row>
                            )
                        }

                    </Row>
                )
            }
            <Col grow="1">
                <ScrollContainer>
                    <Col className={css.Content}>
                        {renderChildren()}
                    </Col>
                </ScrollContainer>
            </Col>
        </div>
    )
});

export {CardItem};
