import { useDispatch, useSelector } from 'react-redux';
import {getICD10CodesAction} from "../Redux/Slices/Static/Static.slice";

export const useStatic = (): any => {

    const ICD10Codes = useSelector((state: any) => state.static).ICD10Codes;

    const dispatch = useDispatch();


    // Get ICD10 Codes
    const getICD10Codes = (codes: string[]) => {
        dispatch(getICD10CodesAction([codes]));
    };

    return {
        getICD10Codes,
        ICD10Codes
    };
}