import { configureStore } from '@reduxjs/toolkit';
import { signalRInvokeMiddleware } from '../Middleware/Connector';
import typesReducer from './Types/Types.slice';
import modulesReducer from './Modules/Modules.slice';
import projectsReducer from './Projects/Projects.slice';
import rulesReducer from './Rules/Rules.slice';
import rulesetsReducer from './Rulesets/Rulesets.slice';
import accountReducer from './Account/Account.slice';
import notificationsReducer from './Notifications/Notifications.slice';
import projectTemporaryReducer from './Projects/ProjectTemporary.slice';
import rulesetDataReducer from './RulesetData/RulesetData.slice';
import clientsReducer from './Clients/Clients.slice';
import moduleDataReducer from './ModuleData/ModuleData.slice';
import demoReducer from './Demo/Demo.slice';
import clinicalResearchReducer from './ClinicalResearch/ClinicalResearch.slice';
import helpCenterReducer from './HelpCenter/HelpCenter.slice';
import questionnaireReducer from './Questionnaire/Questionnaire.slice';
import statisticsReducer from './Statistics/Statistics.slice';
import themeReducer from './Theme/Theme.slice';
import reportsReducer from './Reports/Reports.slice';
import prioritiesReducer from './Priorities/Priorities.slice';
import clientContactsReducer from './ClientContacts/ClientContacts.slice';
import staticReducer from './Static/Static.slice';

// Store
const store =  configureStore({
    reducer: {
        types: typesReducer,
        modules: modulesReducer,
        projects: projectsReducer,
        rules: rulesReducer,
        rulesets: rulesetsReducer,
        account: accountReducer,
        notifications: notificationsReducer,
        projectTemporary: projectTemporaryReducer,
        rulesetData: rulesetDataReducer,
        clients: clientsReducer,
        moduleData: moduleDataReducer,
        demo: demoReducer,
        clinicalResearch: clinicalResearchReducer,
        helpCenter: helpCenterReducer,
        questionnaire: questionnaireReducer,
        statistics: statisticsReducer,
        theme: themeReducer,
        reports: reportsReducer,
        priorities: prioritiesReducer,
        clientContacts: clientContactsReducer,
        static: staticReducer
    },
    middleware: [signalRInvokeMiddleware]
});

// Exports
export type RootState = ReturnType<typeof store.getState>;
export default store;