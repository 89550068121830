import {getICD10CodesAction} from "../Static/Static.slice";

export function receiveClientICD10CodesCallback(store: any, payload: any) {
  const data = payload.data;
  const codes = findICD10CodesFromRafScore(data);

  store.dispatch(getICD10CodesAction([codes]));
}

function findICD10CodesFromRafScore(scoreData: any): string[]{
  const codes: string[] = [];
  Object.values(scoreData).forEach((data: any) => {

    if (!data || !data.hasOwnProperty("hcCs") || !data.hcCs || data.hcCs.length === 0) return;

    data.hcCs.forEach((hcC: any) => {
      hcC.hccScores.forEach((hccScore: any) => {
        const localCodes = hccScore.icD10Codes;

        // add codes to the list (if not already there)
        localCodes.forEach((code: any) => {
          if(!codes.includes(code)) codes.push(code);
        });
      });
    });
  });

  return codes;
}