import { useDispatch, useSelector } from 'react-redux';
import {
    getClientSchemeAction,
    getClientScoresAction,
    getGetClientICD10CodesAction
} from "../Redux/Slices/Clients/Clients.slice";

export const useClients = (): any => {

    const clientsData = useSelector((state: any) => state.clients);
    const clientScheme = useSelector((state: any) => state.clients).scheme;
    const activeClientICD10Codes = useSelector((state: any) => state.clients).activeClientICD10Codes;
    const clientScores = useSelector((state: any) => state.clients).clientScores;

    const dispatch = useDispatch();

    // Clone Rule
    const getClientScheme = () => {
        dispatch(getClientSchemeAction([]));
    };

    // Get Client Scores
    const getClientScores = (patientId: string, HMSProjectId: string) => {
        dispatch(getClientScoresAction([patientId, HMSProjectId]));
    };

    // Get Client ICD10 Codes
    const getGetClientICD10Codes = (clientId: string, flowId: string = "", nodeId: string = "", HMSProjectId: string = '') => {
        dispatch(getGetClientICD10CodesAction([clientId, flowId, nodeId, HMSProjectId]));
    };

    return {
        getClientScheme, getGetClientICD10Codes, getClientScores,
        clientsData, clientScheme, activeClientICD10Codes, clientScores
    };
}