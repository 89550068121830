import { createSlice } from '@reduxjs/toolkit';

interface IStaticTypeState {
    ICD10Codes: any,
}

const initialState:IStaticTypeState = {
    ICD10Codes: {}
};

export const staticSlice = createSlice({
    name: 'Static',
    initialState,
    reducers: {

        // Get ICD10 Codes
        getICD10CodesAction: (state, action) => {
            console.log('Get ICD10 Codes --- ', action.payload);
        },

        // Receive Static ICD10 Codes
        receiveStaticICD10CodesAction: (state, action) => {
            state.ICD10Codes = action.payload.data;
            console.log('Receive Static ICD10 Codes Action --- ', action.payload);
        },
    }
});

export const {
    getICD10CodesAction,
    receiveStaticICD10CodesAction
} = staticSlice.actions;
export default staticSlice.reducer;