import { FC } from 'react';
import { Row, Col, Text } from 'lavaa';

type Props = {
    value: number | string;
    highLightGtZero?: boolean;
};

// MoneyCellRenderer Component
export const MoneyCellRenderer: FC<Props> = (props: Props) => {
    const { value, highLightGtZero } = props;

    // Format Value
    const formattedValue = value === null ? null : Number(value).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    });

    const textColor = highLightGtZero === true && Number(value) > 0 ? 'var(--secondary-button-text-color)' : undefined;
    
    return (
        <Row grow="1" shrink="1" justifycontent="center" alignitems="center" style={{whiteSpace: 'normal'}}>
            <Text bold={Number(value) > 0} size="x2" style={{textAlign: 'center', color: textColor}}>{formattedValue}</Text>
        </Row>
    )
};