import {ChangeEvent, FC, MutableRefObject, RefObject, useCallback, useEffect, useState} from 'react';
import {MethodsType, UpdateSchemaType} from './DataTable.types';
import { classNames } from '../../Tools';
import { ColDef, FilterChangedEvent, FilterModifiedEvent, GridApi, SortChangedEvent } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { Button, Col, Modal, Row, Text } from 'lavaa';
import { Pagination } from './Pagination/Pagination.com';
import DatePicker from "react-datepicker";

// Hooks
import { useTheme } from '../../Hooks/UseTheme';

// Filters
import { YesNoFilter } from './Filters/YesNoFilter.com';

// Renderers
import { getRenderer } from './CellRenderens/MapRenderers';
import { ButtonCellRenderer } from './CellRenderens/ButtonCellRenderer/ButtonCellRenderer.com';
import { LinkCellRenderer } from './CellRenderens/LinkCellRenderer/LinkCellRenderer.com';
import { TextCellRenderer } from './CellRenderens/TextCellRenderer/TextCellRenderer.com';
import { BoolCellRenderer } from './CellRenderens/BoolCellRenderer/BoolCellRenderer.com';
import { ListCellRenderer } from './CellRenderens/ListCellRenderer/ListCellRenderer.com';
import { ArraySimpleCellRenderer } from './CellRenderens/ArraySimpleCellRenderer/ArraySimpleCellRenderer.com';
import { HtmlCellRenderer } from './CellRenderens/HtmlCellRenderer/HtmlCellRenderer.com';
import { SnoozeRenderer } from './CellRenderens/SnoozeRenderer/SnoozeRenderer.com';
import { PatientCellRenderer } from './CellRenderens/PatientCellRenderer/PatientCellRenderer.com';

// Styles
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import './DataTable.css';
import './AgGridLavaa.css';
import css from './DataTable.module.scss';
import { SnoozeRendererNew } from './CellRenderens/SnoozeRenderer/SnoozeRendererNew.com';
import { SnoozeRendererConfirmOnly } from './CellRenderens/SnoozeRenderer/SnoozeRendererConfirmOnly.com';
import React from 'react';


type Props = {
    tableId?: string,
    headers: string[];
    rows?: any[];
    defaultColDef?: any;
    pageSizes?: number[];
    totalPages?: number;
    deleteAction?: boolean;
    editable?: boolean;
    onGridReady?: (e: any) => void;
    onAction?: (eventType: string, data: any, tableId?: string) => void;
    primaryKey?: string;
    sortModel?: any[];
    columnModel?: any[];
    floatingFilter?: boolean;
    hideFloatingFilter?: boolean;
    useServer?: boolean;
    displayLoadingOverlay?: boolean;
    minColumnWidth?: number;
    reload?: number;
    filteredHeadersLastUpdate?: number;
    onRowSelected?: (e: any) => void;
    onColumnMoved?: (e: any) => void;
    onGridSizeChanged?: (e: any) => void;
    methods?: MutableRefObject<MethodsType | null>;
};

// DataTable Component
export const DataTable: FC<Props> = (props: Props) => {

    // Props
    const { tableId = '', headers = [], rows = [], defaultColDef = {flex: 1}, pageSizes = [10, 25, 50, 100], totalPages = 1, onGridReady, onAction, onRowSelected, onColumnMoved } = props;
    const { deleteAction = false, editable = false, primaryKey = '_id', columnModel = [] } = props;
    const { floatingFilter = true, hideFloatingFilter = false, useServer = false, minColumnWidth, reload = 0 } = props;
    const { filteredHeadersLastUpdate = 0, methods, onGridSizeChanged } = props;

    // Hooks
    const { theme } = useTheme();
    
    // States
    const [isGridReady, setGridReady] = useState<boolean>(false);
    const [gridApi, setGridApi] = useState<GridApi | null>(null);
    const [colDefs, setColDefs] = useState<ColDef[]>([]);
    const [localRows, setLocalRows] = useState(rows);
    const [pageSize, setPageSize] = useState(10);
    const [pageNumber, setPageNumber] = useState(1);
    const [initialized, setInitialized] = useState(false);
    const [loading, setLoading] = useState(true);
    const [filterUpdated, setfilterUpdated] = useState(0);
    const [filterModelState, setFilterModelState] = useState<any | null>(null);

    // Refs
    const gridApiRef = React.useRef<GridApi | null>(null);

    // Handle Change Page
    const handleChangePage = (page: number, pageSize: number) => {
        setPageNumber(page);
        setPageSize(pageSize);
        handleAction('paginationChanged', {
            pageNumber: page,
            pageSize: pageSize,
        });
    };

    const handleResetFilterCol = (colId: string) => {
        if (gridApiRef.current) {
            gridApiRef.current.destroyFilter(colId);
        }
    }

    const handleResetFilter = () => {
        if (gridApiRef.current) {
            gridApiRef.current.setFilterModel(null);
        }
    }

    const handleChangePageMethod = (page: number) => {
        setPageNumber(page);

        if (gridApiRef.current) {
            gridApiRef.current.paginationGoToPage(page);
        }
    }

    const handleSizeColumnsToFit = () => {
        if (gridApiRef.current) {
            gridApiRef.current.sizeColumnsToFit();
        }
    }

    // Normalize & Capitalize Column Name
    const normalizeColumnName = (name: string) => name.toLowerCase().replace(/_/g, ' ');
    const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

    // Handle Action
    const handleAction = (actionType: string, data: any) => {
        if (useServer === true) {
            setLoading(true);
            // setLocalRows([]);
        }
        onAction && onAction(actionType, data, tableId);
    };

    // Get Filter Model
    const getFilterModel = () => {
        if (gridApiRef.current) {
            return gridApiRef.current.getFilterModel();
        }
        return null;
    };

    // Add a function to restore the filter state
    const restoreFilterModel = () => {
        if (gridApiRef.current) {
            // console.log('restoreFilterModel', filterModelState);
            
            gridApiRef.current.setFilterModel(filterModelState);
        }
    };

    // On Filter Changed
    const handleFilterChanged = (event: FilterChangedEvent) => {
        // setTimeout(() => {
            // console.log('On Filter Changed', event.api.getFilterModel());
            if (gridApiRef.current) {
                const filterModel = getFilterModel();
                // const filterModel = {
                //     PatientIdFullName: {
                //         filter: "asd",
                //         filterType: "text",
                //         type: "equals"
                //     }
                // };
                setfilterUpdated(performance.now());
                setFilterModelState(filterModel);
                // gridApi.showLoadingOverlay();
                handleAction('filterChanged', gridApiRef.current.getFilterModel());
                setPageNumber(1);
            }
        // }, 1000);
    };

    // On Filter Modified
    const handleFilterModified = (event: FilterModifiedEvent) => {
        setTimeout(() => {
            if (gridApiRef.current) {
                console.log('On Filter Modified', event);
                
                // setfilterUpdated(performance.now());
                // gridApi.showLoadingOverlay();
                // handleAction('filterChanged', gridApi.getFilterModel());
            }
        }, 1000);
    };

    // On Sort Changed
    const handleSortChanged = (e: SortChangedEvent) => {
        if (gridApiRef.current) {
            const sortState = gridApiRef.current.getColumnState().find((s: any) => s.sort);
            // gridApi.showLoadingOverlay();
            handleAction('sortChanged', sortState);
        }
    };

    // On Pagination Changed
    // const handlePaginationChanged = () => {
    //     if (gridApi) {
    //         gridApi.showLoadingOverlay();
    //         handleAction('paginationChanged', {
    //             pageNumber: gridApi.paginationGetCurrentPage(),
    //             pageSize: gridApi.paginationGetPageSize(),
    //         });
    //     }
    // };

    // On Cell Value Changed
    const handleCellValueChanged = (params: any) => {
        const updatedRowData = { ...params.data, [params.colDef.field]: params.newValue };
        
        setLocalRows(prevRows => prevRows.map(row => row[primaryKey] === updatedRowData[primaryKey] ? updatedRowData : row));
        handleAction('update', { 
            data: {
                [primaryKey]: updatedRowData[primaryKey],
                [params.colDef.projectField || params.colDef.field]: params.newValue
            },
            schema: {
                primaryKey: primaryKey,
                fields: [params.colDef.projectField || params.colDef.field]
            }
        } as UpdateSchemaType);
    };

    // On Column Visible
    const onColumnVisible = (e: any) => {
        if (gridApiRef.current) {
            console.log('DSS', e);
            
            // const { column, visible } = e;
            // const { colId } = column;
            
            // handleAction('columnVisible', { colId, visible });
        }
    };

    // Check if Filter is Disabled
    const isFilterDisabled = (header: any) => {
        const filterModel = gridApiRef.current?.getFilterModel();
        const key = typeof header === 'string' ? header : header.field;

        if (filterModel && filterModel[key]) {
            const { type } = filterModel[key];
            
            if (type === 'blank' || type === 'notBlank') {
                return 'disabled';
            }
        }
        
        return true;
    };

    // Init Column Defs
    const initColDefs = (a: any) => {  
        console.log('Init Column Defs', a);
              
        const columnDefs: any[] = headers.map((header: any, index: number) => ({ 
            index,
            headerName: typeof header === 'string' ? capitalize(normalizeColumnName(header)) : header.headerName ? header.headerName : '---', 
            autoHeight: true,
            // cellStyle: {color: 'red'},
            // rowHeight: 100,
            // wrapText: true,
            field: typeof header === 'string' ? header : header.field ? header.field : '---',
            sortable: true,
            filter: isFilterDisabled(header) && header.hasFilter != false,//true,
            floatingFilter: floatingFilter,
            // suppressMenu: true,
            suppressFloatingFilterButton: hideFloatingFilter,
            suppressDragLeaveHidesColumns: true,
            filterParams: {
                suppressAndOrCondition: true,
                // filterOptions: []
                filterOptions: [
                    'contains', 
                    'notContains', 
                    'startsWith', 
                    'endsWith', 
                    'equals', 
                    'notEqual', 
                    'blank',
                    'notBlank',
                    'search',
                    // 'greaterThan', 
                    // 'lessThan', 
                    // 'inRange'
                ],
            },
            resizable: true,
            editable: editable, //header !== primaryKey,
            cellDataType: false,
            hide: header === primaryKey,
            minWidth: minColumnWidth,
            cellRenderer: TextCellRenderer
            // pinned: header === 'event' ? 'left' : undefined,
        }));

        console.log('Col Defs', columnDefs);

        // Apply Custom Column Model
        const mergedColumnDefs: ColDef[] = columnDefs.map((colDef: ColDef): ColDef => {
            const modelConfig = columnModel.find(m => m.field === colDef.field);
            const triangle = 'url("data:image/svg+xml,<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M11.646 15.146 5.854 9.354a.5.5 0 0 1 .353-.854h11.586a.5.5 0 0 1 .353.854l-5.793 5.792a.5.5 0 0 1-.707 0Z"></path></svg>")';
            
            // Custom Model Config
            if (modelConfig) {
                const customModelConfig = { 
                    ...colDef, 
                    ...modelConfig,
                    cellClass: modelConfig.editable ? css.EditableCell : undefined,
                    // cellStyle: modelConfig.editable ? { backgroundImage: triangle } : undefined,
                    // cellDataType: modelConfig.type || 'text'
                };

                // Add custom filters
                const filter = modelConfig.filter;
                if (filter) {

                    // yesNoFilter
                    if (filter === 'yesNoFilter') {
                        customModelConfig['filter'] = YesNoFilter;
                        console.log('filter', filter);
                    }
                }

                // Add renderers
                const renderer = modelConfig.renderer;
                if (renderer) {

                    // Dynamic Renderer
                    if (Array.isArray(renderer)) {

                        // Renderers
                        customModelConfig['cellRenderer'] = (props: any) => renderer.map((r: any, index: number) => {
                            
                            // Rendered defined as string - with no props
                            if (typeof r === 'string') {
                                return <React.Fragment key={index}>{getRenderer(r, props)}</React.Fragment>;
                            }

                            // Rendered defined as object - with props
                            if (typeof r === 'object' && r !== null) {
                                
                                // Options
                                if (r.options) {
                                    // useHandleAction option
                                    if (r.options.useHandleAction) {
                                        !r.props && (r.props = {});
                                        r.props.handleAction = handleAction;
                                    }
                                }
                                return <React.Fragment key={index}>{getRenderer(r.name, {...props, ...r.props})}</React.Fragment>;
                            }
                        });
                    }

                    // String
                    // else if (typeof renderer === 'string') {
                    //     customModelConfig['cellRenderer'] = (props: any) => <React.Fragment>{getRenderer(renderer, props)}</React.Fragment>;
                    // }

                    // Snooze/Unsnooze Button
                    else if (renderer === 'button' && modelConfig.action === 'snoozePatient') {
                        customModelConfig['cellRenderer'] = (props: any) => <SnoozeRenderer {...props} handleAction={handleAction} />;
                    }

                    // Snooze/Unsnooze Button New
                    else if (renderer === 'button' && modelConfig.action === 'snoozePatientNew') {
                        console.log('SNZ 1');
                        
                        customModelConfig['cellRenderer'] = (props: any) => <SnoozeRendererNew {...props} handleAction={handleAction} />;
                    } 

                    // Snooze Confirm Only
                    else if (renderer === 'button' && modelConfig.action === 'snoozePatientConfirmOnly') {
                        customModelConfig['cellRenderer'] = (props: any) => <SnoozeRendererConfirmOnly {...props} handleAction={handleAction} />;
                    } 

                    // Link
                    else if (renderer === 'link') {
                        customModelConfig['cellRenderer'] = LinkCellRenderer;
                    }

                    // Bool
                    else if (renderer === 'bool') {
                        customModelConfig['cellRenderer'] = BoolCellRenderer;
                    } 

                    // List
                    else if (renderer === 'list') {
                        customModelConfig['cellRenderer'] = ListCellRenderer;
                    } 

                    // Array Simple
                    else if (renderer === 'arraySimple') {
                        customModelConfig['cellRenderer'] = ArraySimpleCellRenderer;
                    } 

                    // Html
                    else if (renderer === 'html') {
                        customModelConfig['cellRenderer'] = HtmlCellRenderer;
                    }

                    // Function
                    else if (typeof renderer === 'function') {
                        customModelConfig['cellRenderer'] = renderer;
                    }

                    // Custom Renderer
                    else {
                        customModelConfig['cellRenderer'] = renderer;
                    }
                } else {
                    customModelConfig['cellRenderer'] = TextCellRenderer;
                }
                
                return customModelConfig;
            }

            return colDef;
        });
        // console.log('mergedColumnDefs', mergedColumnDefs);
        
        setColDefs(mergedColumnDefs);
    };

    // Watch Rows & Headers
    useEffect(() => {
        if (!isGridReady) return;
        // console.log('Watch Rows & Headers');
        initialized && setLoading(false);
        setInitialized(true);
        
        // Set Local Rows
        // if (localRows.length !== rows.length) {
            setLocalRows(rows);
        // }

        // Init Column Definitions
        if (colDefs.length === 0 && headers.length > 0) {
            // console.log('Init Column Definitions');
            
            initColDefs(111);
        }
    }, [rows, headers, isGridReady]);
    
    // Update Column Definitions
    useEffect(() => {
        if (filterUpdated && isGridReady) {
            // console.log('Update Column Definitions', filterUpdated);
            initColDefs(222);
        }
    }, [filterUpdated, isGridReady]);

    // On Grid Ready
    const handleGridReady = useCallback((params: any) => {
        // console.log('GRID READY');
        
        gridApiRef.current = params.api;
        setGridReady(true);
        onGridReady && onGridReady(params);

        if(methods != undefined){
            methods.current = {
                resetFilter: handleResetFilter,
                resetFilterCol: handleResetFilterCol,
                changePage: handleChangePageMethod,
                sizeColumnsToFit: handleSizeColumnsToFit,
            };
        }
    }, []);
    
    // Handle Drag Stopped
    const handleDragStopped = (event: any) => {
        try {
            if (gridApiRef?.current && tableId) {
                const columnsOrderKey = tableId + '_ColumnFilter';
                const allColumns = gridApiRef.current.getAllDisplayedColumns();
                const columnOrder: any = allColumns.map((col: any) => col.colId).reduce((acc: any, key: string, index: number) => {
                    acc[key] = index;
                    return acc;
                }, {});

                const tableConfigStored: string | null = localStorage.getItem(columnsOrderKey);
                const tableConfig: any | null = tableConfigStored ? JSON.parse(tableConfigStored) : null;

                if (tableConfig !== null) {
                    const sortedColDefs = colDefs.map((item: any) => {
                        return {
                            ...item,
                            index: columnOrder[item.field]
                        };
                    }).sort((a: any, b: any) => a.index - b.index);

                    const sortedTableConfig = tableConfig.map((item: any) => {
                        return {
                            ...item,
                            index: columnOrder[item.field]
                        };
                    }).sort((a: any, b: any) => a.index - b.index);

                    localStorage.setItem(columnsOrderKey, JSON.stringify(sortedTableConfig));
                    setColDefs(sortedColDefs);
                }
            }
        } catch (err) {
            console.log('Error:', err);
        }
    };

    // Filtered Headers Updates
    useEffect(() => {
        if (filteredHeadersLastUpdate && isGridReady) {
            setTimeout(() => {
                initColDefs(333);
            }, 100);
        }
    }, [filteredHeadersLastUpdate]);

    // Reload
    useEffect(() => {
        if (reload > 0) {
            handleAction('reload', {});
        }
    }, [reload]);

    // Filtered Headers Updates
    useEffect(restoreFilterModel);
    // console.log('loading', loading);
    return (
        <Col grow="1" className={classNames(css.DataTable, theme === 'dark' ? 'ag-theme-lavaa-dark' : 'ag-theme-lavaa')} data-server={useServer}>

            {/* Grid */}
            <AgGridReact 
                loading={loading}
                overlayLoadingTemplate={'Loading...'}
                className={css.DataTable}
                onGridReady={handleGridReady}
                columnDefs={colDefs}
                rowData={loading ? [] : localRows}
                defaultColDef={defaultColDef}
                onFilterChanged={handleFilterChanged}
                // onFilterModified={() => console.log('Filter Modified')}
                // onFilterModified={handleFilterModified}
                onSortChanged={handleSortChanged}
                // onPaginationChanged={handlePaginationChanged}
                onCellValueChanged={handleCellValueChanged}
                pagination={!useServer}
                paginationPageSize={pageSize}
                loadingOverlayComponent={'Loading...'}
                localeText={{
                    // noRowsToShow: loading ? 'Loading...' : 'No data available'
                    noRowsToShow: 'No data available'
                }}
                // reactiveCustomComponents={true}
                onColumnVisible={onColumnVisible}
                rowSelection={onRowSelected ? "single" : undefined}
                onSelectionChanged={onRowSelected}
                onColumnMoved={onColumnMoved}
                onGridSizeChanged={onGridSizeChanged}
                suppressDragLeaveHidesColumns={true}
                suppressMoveWhenColumnDragging={false}
                suppressColumnMoveAnimation={false}
                onDragStopped={handleDragStopped}
            />

            {/* Pagination */}
            {
                useServer === false ? null :
                <Pagination page={pageNumber} pageSizes={pageSizes} totalPages={totalPages} onChangePage={handleChangePage} />
            }
        </Col>
    )
};