export type PreparedStatDataType = {
	data: { name: string, code: string, v24Code: boolean, v28Code: boolean }[],
	scores: {
		v24: number,
		v28: number,
		blended: number
	}
}

export const emptyStatData: PreparedStatDataType = {
	data: [],
	scores: {
		v24: 0,
		v28: 0,
		blended: 0
	}
}

export function prepareData(data: any, ICD10Codes: any): PreparedStatDataType{
	const totalScore = {...emptyStatData.scores}

	if(!data || !data.hasOwnProperty("hcCs") || !data.hcCs || data.hcCs.length === 0) {
		return emptyStatData;
	}

	const result: { name: string, code: string, v24Code: boolean, v28Code: boolean}[] = [];

	data.hcCs.forEach((hcC: any) => {
		const model = hcC.engineResult.model;

		// Check if model is valid
		if(!["24", "28"].includes(model)) return;

		// Get model score
		const modelScore = hcC.engineResult.risk_score_adj;

		// Add score to total
		if(model === "24") totalScore.v24 = modelScore;
		if(model === "28") totalScore.v28 = modelScore;

		// Get codes
		const codes = findCodes(hcC.hccScores);

		codes.forEach((code: string) => {
			// Check if code is already in the list
			const index = result.findIndex((item) => item.code === code);

			// if it is, update it
			if(index >= 0){
				if(model === "24") result[index].v24Code = true;
				if(model === "28") result[index].v28Code = true;
			}
			else{
				result.push({
					name: getCodeName(code, ICD10Codes),
					code: code,
					v24Code: model === "24",
					v28Code: model === "28"
				});
			}
		});
	});

	totalScore.blended = 0.67 * totalScore.v24 + 0.33 * totalScore.v28;

	return {
		data: result,
		scores: totalScore
	}
}

function findCodes(hccScores: any){
	const codes: string[] = [];
	hccScores.forEach((hccScore: any) => {
		const localCodes = hccScore.icD10Codes;

		// add codes to the list (if not already there)
		localCodes.forEach((code: any) => {
			if(!codes.includes(code)) codes.push(code);
		});
	});

	return codes
}

function getCodeName(code: string, ICD10Codes: any){
	const index = ICD10Codes.findIndex((item: any) => item.code === code || item.normalizedCode == code);
	if(index === -1) return code;
	return ICD10Codes[index].description;
}