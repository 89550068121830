export type PreparedCode = {
	name: string,
	code: string,
	hccCode: string,
	model: '24' | '28',
	score: number,
	hasImpact: boolean
}

export type PreparedMissingCodesDataType = {
	codes: PreparedCode[],
	cost: number
}

export const emptyMissingCodesData: PreparedMissingCodesDataType = {
	codes: [],
	cost: 0
}


export function prepareData(data: any, scoreKeyA: string, scoreKeyB: string, ICD10Codes: any): PreparedMissingCodesDataType {

	const dataA = data[scoreKeyA];
	const dataB = data[scoreKeyB];

	if(!dataA || !dataB) return emptyMissingCodesData;

	const preparedCodesA = prepareCodes(dataA, ICD10Codes);
	const preparedCodesB = prepareCodes(dataB, ICD10Codes);

	const missingCodes = findMissingCodes(preparedCodesA, preparedCodesB)
		.sort((a, b) => {
			// Sort by impact
			if(a.hasImpact && !b.hasImpact) return -1;
			if(!a.hasImpact && b.hasImpact) return 1;

			// Sort by code
			return a.code.localeCompare(b.code);
		});

	// Calculate cost
	const totalScore = Math.max(dataA.score - dataB.score, 0);
	const sum = 12000 * totalScore;

	return {
		codes: missingCodes,
		cost: sum
	}
}

function prepareCodes(data: any, ICD10Codes: any): PreparedCode[]{
	if(!data || !data.hasOwnProperty("hcCs") || !data.hcCs || data.hcCs.length === 0) {
		return [];
	}

	const codes: PreparedCode[] = [];

	data.hcCs.forEach((hcC: any) => {
		const model = hcC.engineResult.model;

		// Check if model is valid
		if (!["24", "28"].includes(model)) return;

		// Get codes
		hcC.hccScores.forEach((hccScore: any) => {
			const hccCode = hccScore.hccCode;
			const localCodes = hccScore.icD10Codes;
			const score = hccScore.score;

			// add codes to the list (if not already there)
			localCodes.forEach((code: any) => {
				const index = codes.findIndex((item) => item.code === code && item.model === model && item.hccCode === hccCode);
				if(index === -1){
					codes.push({
						name: getCodeName(code, ICD10Codes),
						code: code,
						hccCode: hccCode,
						model: model,
						score: score,
						hasImpact: true
					});
				}
			});
		});
	});

	return codes;
}

function getCodeName(code: string, ICD10Codes: any){
	const index = ICD10Codes.findIndex((item: any) => item.code === code || item.normalizedCode == code);
	if(index === -1) return code;
	return ICD10Codes[index].description;
}

function findMissingCodes(preparedCodesA: PreparedCode[], preparedCodesB: PreparedCode[]){
	const missingCodes: PreparedCode[] = [];


	preparedCodesA.forEach((codeA) => {
		const index = preparedCodesB.findIndex((codeB) => codeB.code === codeA.code && codeB.model === codeA.model);
		if(index === -1){
			const missingCode = {...codeA};

			// Check if HCC code exists in B
			const checkHccCode = preparedCodesB.findIndex((codeB) => codeB.hccCode === codeA.hccCode && codeB.model === codeA.model);

			if(checkHccCode >= 0){
				missingCode.hasImpact = false;
			}

			missingCodes.push(missingCode);
		}
	});

	return missingCodes;
}