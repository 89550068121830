import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getReportsAction, runReportsAction, getReportsWithPagerAction, getClientDetailsByIdAction, clearClientDetailsAction } from "../Redux/Slices/Reports/Reports.slice";
import {ReportID, ReportTypes} from "../Redux/Slices/Reports/Reports.types";

export const useReports = (): any => {

    // Selectors
    const reports = useSelector((state: any) => state.reports.reportsData);
    const dashboardReports = useSelector((state: any) => state.reports.reportsDashboardData);
    const snoozeUpdated = useSelector((state: any) => state.reports.snoozeUpdated);
    const clientDetails = useSelector((state: any) => state.reports.clientDetails);
    const clientDetailsLastUpdate = useSelector((state: any) => state.reports.clientDetailsLastUpdate);
    const dispatch = useDispatch();
    
    // States
    const [exportLoading, setExportLoading] = useState(false);

    // Fetch Excel Reports Data
    const fetchExcelReportsData = async (reportType: string, tableName: string, HMSProjectId: null | string = null, accessToken: string, filters: any[] = []) => {
        try {
            setExportLoading(true);
            const response = await fetch(`https://api.lavaa.health/reports`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    reportName: tableName, 
                    projectUID: HMSProjectId, 
                    token: accessToken,
                    filters
                })
            });
            
            const fileNames: any = {
                generalReport: 'Patient_Care_Prioritization_Overview',
                generalReportNew: 'Patient_Care_Prioritization_Overview',
                ccmReport: 'Report_CCM_Patients',
                ccmReportNew: 'Report_CCM_Patients',
                dailyHuddle: 'Report_Daily_Huddle_' + tableName,
                dailyHuddleNew: 'Report_Daily_Huddle_' + tableName,
                awvReport: 'Annual_Wellness_Visit',
                awvReportNew: 'Annual_Wellness_Visit',
                tcm: tableName,
                qrda: 'QRDA_' + tableName,
                missingCodes: tableName,
                rafScore: 'RafScoreReport'
            };

            if (response.ok) {
                const blob = await response.blob();
                if (blob.size > 0) {
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.style.display = 'none';
                    a.href = url;

                    const now = new Date();
                    const year = now.getFullYear();
                    const month = String(now.getMonth() + 1).padStart(2, '0'); // +1 because January is 0
                    const day = String(now.getDate()).padStart(2, '0');
                    const hours = String(now.getHours()).padStart(2, '0');
                    const minutes = String(now.getMinutes()).padStart(2, '0');
                    const seconds = String(now.getSeconds()).padStart(2, '0');

                    a.download = `${fileNames[reportType]}_${year}-${month}-${day}_${hours}-${minutes}-${seconds}.xlsx`;
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                }
            }
            setExportLoading(false);
        } catch (err) {
            // console.error('UseReports.fetchExcelReportsData request error', err);
        }
    };

    // Request Reports
    const requestReports = (HMSProjectId: string, reportName: string, sorting: any = {}, filters: any[] = [], pageNumber: number = 1, pageSize: number = 25) => {
        const pageNum = pageNumber-1 >= 0 ? pageNumber-1 : 0;
        console.log('Request Reports', pageNum);
        
        dispatch(getReportsAction([HMSProjectId, reportName, sorting, filters, pageNum, pageSize]));
    };

    // Request Reports With Pager
    const requestReportsWithPager = (requestID: ReportID, HMSProjectId: string, reportName: string, sorting: any = {}, filters: any[] = [], pageNumber: number = 1, pageSize: number = 25) => {
        const pageNum = pageNumber-1 >= 0 ? pageNumber-1 : 0;
        const pager = {
            "requestID": requestID,
            "pageNumber": pageNum,
            "pageSize": pageSize,
            "sorting": sorting,
            "filterBy": filters,
            "projectUID": HMSProjectId,
        }
        dispatch(getReportsWithPagerAction([HMSProjectId, reportName, pager]));
    };

    // Get Report
    const getReport = (reportName: ReportTypes) => {
        return reports[reportName];
    };

    // Get Dashboard Report
    const getDashboardReport = (reportName: ReportTypes) => {
        return dashboardReports[reportName];
    };

    // Report Action
    const runReportAction = (HMSProjectId: string, spName: string, parameters: any[]) => {
        dispatch(runReportsAction([{
            id: performance.now().toString(),
            projectUID: HMSProjectId,
            spName,
            parameters
        }]));
    };

    // Get Client Details By Id
    const getClientDetailsById = (clientId: number, HMSProjectId: string) => {
        dispatch(getClientDetailsByIdAction([Number(clientId), HMSProjectId]));
    };

    // Clear Client Details
    const clearClientDetails = () => {
        dispatch(clearClientDetailsAction());
    };

    return {
        exportLoading,
        snoozeUpdated,
        clientDetails,
        clientDetailsLastUpdate,
        fetchExcelReportsData,
        requestReports,
        requestReportsWithPager,
        getReport,
        getDashboardReport,
        runReportAction,
        getClientDetailsById,
        clearClientDetails
    };
}